@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

$lineHeight: 3mm;

.container {
  &.preview {
    overflow: auto;
    width: 100%;
    height: 100%;
    .export {
      width: 1800px;
    } 
  }
  .download {
    position: fixed;
    right: 8mm;
    bottom: 8mm;
    z-index: 22;
  }
  .export {
    /* Style pour le pied de page */
    padding: 0px 4mm;
    width: 1800px;
    .header {
      margin-top: 7px;
      margin-bottom: 4px;
      width: 100%;
      h2 {
        font-size: 16px;
        color: $black;
        margin: 0px;
      }
    }
    .pool-row {
      width: 100%;
      border-top: 1px solid $black;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      display: flex;
      align-items: center;
      .pool {
        width: 178px;
        min-width: 178px;
        p {
          margin: 0px;
          font-size: 10px;
          line-height: 10px;
          padding-left: 5px;
        }
      }
      .lines {
        display: flex;
        flex-direction: column;
        width: calc(100% - 178px);
        background-color: #ecf2f7;
        .line {
          height: $lineHeight;
          display: flex;
          border-bottom: 1px solid $black;
          &:last-child {
            .line-label {
              border-bottom: 0px solid $black;
            }
            border-bottom: 0px solid $black;
          }
          .line-label {
            width: 30px;
            min-width: 30px;
            height: $lineHeight;
            background-color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid $black;
            border-bottom: 1px solid $black;
            p {
              margin: 0px;
              font-size: 10px;
              line-height: 10px;
            }
          }
        }
      }
    }
    .pool-end {
      width: 100%;
      border-bottom: 1px solid $black;
    }
    .user-row {
      width: 100%;
      border-top: 1px solid $black;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &.isLast {
        border-bottom: 1px solid $black;
      }
      .user {
        width: 208px;
        min-width: 208px;
        p {
          margin: 0px;
          font-size: 9px;
          line-height: 11px;
          padding-left: 5px;
        }
      }
    }
    .label {
      height: calc(4mm);
      width: 100%;
      display: flex;
      border-top: 1px solid $black;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      .day {
        width: 55mm;
        display: flex;
        align-items: center;
        padding-left: 5px;
        font-size: 10px;
        @include semiBold;
      }
      .hours {
        height: calc(4mm);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .slot {
          height: calc($lineHeight * 2);
          position: relative;
          // &.active {
          //   &::before {
          //     position: absolute;
          //     content:'';
          //     height: $lineHeight;
          //     border-right: 1px solid $black;
          //   }
          // }
          p {
            margin: 0px;
            left: -40px;
            width: 80px;
            position: absolute;
            text-align: center;
            font-size: 10px;
            top: 0.5mm;
            color: $black;
            &.minutes {
              top: 0.5mm;
              font-size: 10px;
              color: rgba($black, 0.5)
            }
          }
        }
      }
    }
    // .break {
    //   page-break-before: always;
    // }
    .space {
      height: 2mm;
    }
  }
  .shifts {
    height: $lineHeight;
    display: flex;
    position: relative;
    .slots {
      height: $lineHeight;
      display: flex;
      align-items: center;
      justify-content: flex-start;     
      z-index: 1;     
      .slot {
        height: $lineHeight;
        position: relative;
        position: relative;
        z-index: 2;
        &.hour {
          &::before {
            position: absolute;
            content:'';
            height: $lineHeight;
            border-right: 1px solid $black;
          }
        }
        &.half {
          &::before {
            position: absolute;
            content:'';
            height: $lineHeight;
            border-right: 1px solid rgba($black, 0.4);
          }
        }
      }
    }
    .step {
      position: absolute;
      display: flex;
      height: $lineHeight;
      overflow: hidden;
      &:not(.isLast) {
        border-bottom: 1px solid $black;
      }
      p {
        top: -3px;
        left: 1px;
        position: relative;
        width: auto;
        z-index: 10;
        padding: 1px 3px;
        font-size: 10px;
        color: $black;
        height: 20px;
      }
    }
    .count {
      width: 30px;
      height: $lineHeight;
      @include flex-center;
      p {
        font-size: 10px;
        margin-bottom: 0px;
      }
    }
  }
  .users-table {
    display: flex;
    justify-content: flex-start;
    .users {
      border: 1px solid $black;
      margin-top: 20px;
      .user {
        display: flex;
        &:not(:last-child) {
          border-bottom: 1px solid $black;
        }
        p {
          margin: 0px;
          @include semiBold;
          font-size: 11px;
          padding: 2px 5px;
          text-align: right;
          width: 60px;
          min-width: 60px;
          &:first-child {
            width: 208px;
            min-width: 208px;
            border-right: 1px solid $black;
            text-align: left;
          }
        }
      }
    }
  }
  .footer {
    // position: fixed;
    // bottom: 8mm;
    // left: 8mm;
    padding: 4px 4mm;
    z-index: 20;
    text-align: left;
    font-size: 14px;
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    span {
      @include semiBold;
      font-size: 12px;
    }
    .items {
      display: block;
      margin-top: 5px;
      .item {
        display: inline-block;
        margin-right: 15px;
        p {
          font-size: 12px;
          line-height: 3mm;
        }
        .color {
          width: 30px;
          height: $lineHeight;
          margin-right: 8px;
        }
      }
    }
  }
}