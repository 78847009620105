@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  .bg {
    left: 0px;
    height: 130px;
    width: 100%;
    background-color: #F6F7F8;
    background-image: url("../../assets/images/water.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
  }
  .content {
    padding: 20px;
    .avatar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .name {
        margin: 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        h1 {
          @include semiBold;
          font-size: 26px;
          line-height: 22px;
          margin: 0px;
          color: $primary-color;
        }
        p {
          font-size: 20px;
          line-height: 18px;
          margin: 5px 0px;
        }
        .poles {
          display: flex;
          p {
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-size: 14px;
            padding: 4px 10px;
            border-radius: 30px;
            text-align: center;
            margin: 0px 6px 6px 0px;
            color: $white;              
          }
        }
      }
      @media only screen and (min-width: $xl-screen) {
        margin-left: 10%;
        transform: translateY(-30px);
        margin-bottom: 0px;
        .name {
          flex-direction: row;
          align-items: flex-end;
          padding-top: 0px;
          h1 {
            font-size: 26px;
            margin-right: 10px;
          }
          p {
            margin: 0px;
          }
          .poles {
            position: absolute;
            top: 85px;
          }
        }
      }
    }
    .form {
      h2 {
        color: $primary-color;
        margin-bottom: 20px;
        font-size: 16px;
      }
      .container-field {
        @include containerField();
        label {
          @include label;
          display: block;
          margin-bottom: 10px;
        }
        .color {
          border: solid 1px #d8d7da;
          width: 57px;
          @include flex-center;
          height: 32px;
          border-radius: 8px;
        }
        .reset-color {
          margin-top: 5px;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .links {
        display: flex;
        .link {
          @include semiBold;
          text-decoration: none;
          margin-right: 10px;
          font-size: 18px;
          &:first-child {
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
          }
        }
      }
      .profile {
        margin-top: 20px;
      } 
      .col {
        margin-bottom: 25px;
      }
      .infos {
        .field {
          display: flex;
          width: 100%;
          p {
            margin-bottom: 8px;
            &:first-child {
              width: 50%;
              padding-right: 10px;
              min-width: 100px;
              max-width: 130px;
              opacity: 0.8;
            }
            &:last-child {
              color: $black;
              width: 50%;
            }
          }
        }
      }
      .logout {
        padding: 20px 0px;
        display: flex;
        justify-content: center;
        button {
          cursor: pointer;
          background-color: transparent;
          @include semiBold;
          display: flex;
          align-items: center;
          border: none;
          padding: 0px;
          color: $primary-color;
          svg {
            font-size: 20px;
            margin-right: 5px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: $xl-screen) {
    .bg {
      display: block;
    }
    .content {
      padding: 0px;
      .form {
        padding: 0px 10%;
        .profile, .infos {
          display: flex;
          .col {
            display: flex;
            flex-direction: column;
            width: 30%;
            padding-right: 20px;
            &:last-child {
              width: calc(70% - 40px);
              margin-left: 40px;
            } 
          }
          &.isProfile {
            .col {
              width: 30%;
              &:last-child {
                width: 30%;
                margin-left: 0px;
              } 
            }
          }
        }
        .logout {
          display: block;
        }
      }
      .infos {
        margin-top: 20px;
      }
    }
  }
}

.list {
  margin-top: 30px;
  width: 100%;
  @media only screen and (min-width: $xl-screen) {
    margin-top: 20px;
  }
  .contract {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    @media only screen and (min-width: $xl-screen) {
      flex-direction: row;
      padding: 0px;
    }
    .contract-col {
      width: 100%;
      padding: 5px;
      margin: 0px;
      @media only screen and (min-width: $xl-screen) {
        width: calc(40% / 3);
        padding: 10px;
        &:nth-child(2) {
          width: calc(60%);
        }
      }
    }
    &.labels {
      color: rgba($black, 0.5);
      display: none;
      @media only screen and (min-width: $xl-screen) {
        display: flex;
      }
    }
    &:not(.labels) {
      @include transition;
      border: 1px solid #CFCFCF;
      border-radius: 5px;
      margin-bottom: 6px;
      cursor: pointer;
      &:hover {
        background-color: rgba($black, 0.05);
      }
    }
  }
}